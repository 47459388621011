export default {
    data() {
        return {
            itemsGroup: {
                column: []
            },
            listDeploy: {
                row: [
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "menuid",
                        connector: {
                            props: {
                                required: true,
                                label: "二级栏目ID",
                                disabled: true
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "mname",
                        connector: {
                            props: {
                                required: true,
                                label: "二级栏目名称",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "WAutocomplete",
                        property: "orgmenuid",
                        connector: {
                            props: {
                                required: true,
                                label: "一级栏目",
                                items: []
                            },
                            on: {
                                especially: (v) => {
                                    console.log(v);
                                    if (v === "pchome") {
                                        this.$set(this.listDeploy.row[6].setCol?.domProps, 'style', "display: block;");
                                    } else {
                                        this.$set(this.listDeploy.row[6].setCol?.domProps, 'style', "display: none;");
                                    }
                                }
                            }
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "WAutocomplete",
                        property: "comptype",
                        connector: {
                            props: {
                                // required: true,
                                // 现有的表单构建器没做有对change事件覆盖处理
                                // 将在下个版本中更新
                                label: "栏目模版",
                                items: [],
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "WAutocomplete",
                        property: "ordertype",
                        connector: {
                            props: {
                                // required: true,
                                label: "新增内容排序",
                                items: []
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "seq",
                        connector: {
                            props: {
                                label: "排序号",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "WSwitches",
                        property: 'show',
                        disabledDeleteDefault: true,
                        connector: {
                            props: {
                                // extension: {
                                //     disabled: true,
                                // },
                                label: '显示状态',
                                inset: true,
                                dense: true
                            }
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "moreurl",
                        connector: {
                            props: {
                                label: "更多链接",
                            },
                            // domProps: {
                            //     style: "display: none;"
                            // }
                        },
                        setCol: {
                            domProps: {
                                style: "display: none;"
                            }
                        }
                    }
                ]
            }
        }
    },
    methods: {

    },
    watch: {
    },
    mounted() {
        // const that = this;
        // function a() {
        //     that.listDeploy.row[6].setCol.domProps.style = "display: block;";
        //     setTimeout(() => {
        //         console.log(that.listDeploy.row)
        //     }, 4000);
        // }
        // this.listDeploy.row[2].connector.on = {
        //     especially(v) {
        //         console.log(v);
        //         if (v === "pchome") {
        //             // a();
        //             // 备注：发现一个vue2大漏洞，当某个节点被后续渲染出来或者消失的时候，会导致事件出现问题
        //             // 具体为无法监听change事件
        //             a();
        //         } else {
        //             that.$set(that.listDeploy.row[6].setCol.domProps, 'style', "display: none;");
        //         }
        //     }
        //     // especially: (v) => {
        //     //     // console.log(v);
        //     //     if (v === "pchome") {
        //     //         this.$set(this.listDeploy.row[6].setCol.domProps, 'style', "display: block;");
        //     //     } else {
        //     //         this.$set(this.listDeploy.row[6].setCol.domProps, 'style', "display: none;");
        //     //     }
        //     // }
        // };
    }
}